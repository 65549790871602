import React from "react";
import { formatCurrency, formatDecimal } from "../../lib/numbers";

interface Props {
  total: Reimbursement.Total;
}

export default function Calculation(props: Props) {
  const { totalSum, deductible, deductibleSum, sum } = props.total;

  return (
    <table className="calculation list">
      <tbody>
        <tr>
          <td colSpan={2}>Godkjent kostnad</td>
          <td className="number">{formatCurrency(totalSum)}</td>
        </tr>
        <tr>
          <td>Egenandel</td>
          <td className="number">{formatDecimal(deductible)}%</td>
          <td className="number">{formatCurrency(deductibleSum)}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <b>Sum til utbetaling</b>
          </td>
          <td className="number">
            <b>{formatCurrency(sum)}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

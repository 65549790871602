import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Field, TextField } from "../FormInput";

interface Props {
  cost: Reimbursement.Cost;
  dispatch: (action: Reimbursement.CostsAction) => void;
  locked: boolean;
}

export default function CostItem(props: Props) {
  const { cost, dispatch, locked } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: cost.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleChange = (attr: string) => (val: string) => {
    props.dispatch({
      type: "update",
      payload: { ...cost, [attr]: val }
    });
  };

  const handleRemove = (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.dispatch({ type: "delete", payload: cost });
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.which === 13) {
      evt.preventDefault();
      dispatch({ type: "add", payload: cost });
    }
  };

  return (
    <div
      className="row"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}>
      <TextField
        name="name"
        autoFocus={!cost.name}
        label="Hva som er kjøpt inn"
        disabled={locked}
        onChange={handleChange("name")}
        onKeyDown={handleKeyDown}
        value={cost.name}
        size={6}
      />
      <TextField
        name="sum"
        label="Sum"
        disabled={locked}
        onChange={handleChange("sum")}
        onKeyDown={handleKeyDown}
        validations={["number"]}
        value={`${cost.sum}` || ""}
        size={2}
      />
      <TextField
        name="correction"
        label="Korrigert"
        disabled={locked}
        onChange={handleChange("correction")}
        onKeyDown={handleKeyDown}
        validations={["number"]}
        value={`${cost.correction}` || ""}
        size={2}
      />
      {!locked && (
        <Field type="button" size={1}>
          <button onClick={handleRemove} className="icon">
            <i className="material-icons">delete</i>
          </button>
        </Field>
      )}
    </div>
  );
}

import React from "react";
import {
  DndContext,
  DragEndEvent,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import CostItem from "./CostItem";
import { skipBlankCosts } from "./useCosts";

interface Props {
  costs: Reimbursement.Cost[];
  dispatch: (action: Reimbursement.CostsAction) => void;
  locked: boolean;
}

function serializeCosts(costs: Reimbursement.Cost[]) {
  return JSON.stringify(
    skipBlankCosts(
      costs.map((c) => {
        return { name: c.name, sum: c.sum, correction: c.correction };
      })
    )
  );
}

export default function Costs(props: Props) {
  const { costs, dispatch, locked } = props;

  const sensors = useSensors(useSensor(PointerSensor));

  const handleAdd = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "add" });
  };

  const handleDragEnd = (evt: DragEndEvent) => {
    const { active, over } = evt;

    if (active.id !== over.id) {
      const ids = costs.map((c) => c.id);
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);
      props.dispatch({
        type: "reorder",
        payload: arrayMove(costs, oldIndex, newIndex)
      });
    }
  };

  return (
    <div className="reimbursement-costs">
      <input
        type="hidden"
        name="reimbursement[costs]"
        value={serializeCosts(costs)}
      />
      {costs.length > 0 && (
        <div className="reimbursement-costs-list">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}>
            <SortableContext
              items={costs.map((c) => c.id)}
              strategy={verticalListSortingStrategy}>
              {costs.map((c) => (
                <CostItem
                  key={c.id}
                  cost={c}
                  dispatch={dispatch}
                  locked={locked}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      )}
      {!locked && (
        <div className="new-cost">
          <button onClick={handleAdd}>
            <i className="material-icons">add</i>
            Legg til linje
          </button>
        </div>
      )}
    </div>
  );
}

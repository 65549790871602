import { ReactNode } from "react";

type TabProps = {
  id: string;
  tab: Recommendation.InterfaceTab;
  children: ReactNode;
};

export default function Tab(props: TabProps) {
  if (props.id == props.tab.id) {
    return <div className="tab">{props.children}</div>;
  } else {
    return <></>;
  }
}

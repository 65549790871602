import { useReducer } from "react";
import { uniqueId } from "lodash";
import { BigNumber } from "bignumber.js";

type State = Reimbursement.Cost[];

export function calculateTotal(
  deductible: number,
  costs: Reimbursement.Cost[]
): Reimbursement.Total {
  const totalSum = costs.reduce((sum, c) => {
    return sum.plus(BigNumber(c.correction || c.sum || 0));
  }, BigNumber(0));

  const deductibleSum = totalSum.times(deductible).dividedBy(100);

  return {
    deductible: deductible,
    deductibleSum: deductibleSum.toFixed(),
    totalSum: totalSum.toFixed(),
    sum: totalSum.minus(deductibleSum).toFixed()
  };
}

function createCost(): Reimbursement.Cost {
  return { id: uniqueId("cost"), name: "", sum: null, correction: null };
}

function insertElement(
  state: State,
  item: Reimbursement.Cost,
  after: Reimbursement.Cost
) {
  if (after) {
    const index = state.indexOf(after);
    return [...state.slice(0, index + 1), item, ...state.slice(index + 1)];
  } else {
    return [...state, item];
  }
}

function reducer(state: State, action: Reimbursement.CostsAction): State {
  switch (action.type) {
    case "add":
      return insertElement(state, createCost(), action.payload);
    case "delete":
      return state.filter((c) => c.id !== action.payload.id);
    case "reorder":
      return action.payload;
    case "update":
      return state.map((c) => {
        if (c.id === action.payload.id) {
          return action.payload;
        } else {
          return c;
        }
      });
    default:
      return state;
  }
}

function prepareCosts(costs: Reimbursement.Cost[]): State {
  return costs.map((c) => {
    return {
      name: c.name,
      sum: c.sum,
      correction: c.correction,
      id: uniqueId("cost")
    };
  });
}

export function skipBlankCosts(costs: Reimbursement.Cost[]) {
  return costs.filter((c) => c.name || c.sum || c.correction);
}

export default function useCosts(initCosts: Reimbursement.Cost[]) {
  return useReducer(reducer, initCosts, prepareCosts);
}

import React, { useState } from "react";

import { submitDeleteForm } from "../lib/request";
import { formatCurrency } from "../lib/numbers";
import { FileUploader, TextArea, TextField } from "./FormInput";
import Costs from "./ReimbursementForm/Costs";
import Calculation from "./ReimbursementForm/Calculation";
import useCosts, { calculateTotal } from "./ReimbursementForm/useCosts";
import { valid, validDraft } from "./ReimbursementForm/validations";

interface Props {
  reimbursement: Reimbursement.Reimbursement;
  locked: boolean;
  deleteUrl?: string;
}

export default function ReimbursementForm(props: Props) {
  const { deleteUrl, locked } = props;
  const { deductible } = props.reimbursement;

  const [costs, dispatchCosts] = useCosts(props.reimbursement.costs);
  const [attachments, setAttachments] = useState<Attachment[]>(
    props.reimbursement.attachments || []
  );

  const [reimbursement, setReimbursement] = useState({
    name: props.reimbursement.name || "",
    account_name: props.reimbursement.account_name || "",
    account_number: props.reimbursement.account_number || "",
    comments: props.reimbursement.comments || ""
  });

  const updateReimbursement = (attr: string) => (val: string) => {
    setReimbursement({ ...reimbursement, [attr]: val });
  };

  const total = calculateTotal(deductible, costs);

  const handleDelete = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (confirm("Er du sikker på at du vil slette denne utbetalingen?")) {
      submitDeleteForm(deleteUrl);
    }
  };

  return (
    <div className="reimbursement-form">
      <h2>Utbetaling</h2>
      <div className="row">
        <TextField
          name="reimbursement[name]"
          label="Navn"
          onChange={updateReimbursement("name")}
          disabled={locked}
          value={reimbursement.name}
          size={9}
        />
      </div>
      <h3>Regnskap</h3>
      <Costs costs={costs} dispatch={dispatchCosts} locked={locked} />
      <div className="row">
        <TextArea
          name="reimbursement[comments]"
          label="Kommenter evt. endringer"
          onChange={updateReimbursement("comments")}
          disabled={locked}
          value={reimbursement.comments}
          size={9}
        />
      </div>
      <h3>Avregning</h3>
      <Calculation total={total} />
      <h3>Til utbetaling</h3>
      <div className="row">
        <TextField
          name="reimbursement[account_number]"
          label="Kontonummer"
          onChange={updateReimbursement("account_number")}
          disabled={locked}
          validations={["accountNumber"]}
          value={reimbursement.account_number}
          size={3}
        />
        <TextField
          name="reimbursement[account_name]"
          label="Navn på kontoeier"
          onChange={updateReimbursement("account_name")}
          disabled={locked}
          value={reimbursement.account_name}
          size={3}
        />
        <TextField
          name="sum"
          label="Sum til utbetaling"
          onChange={() => {}}
          disabled={true}
          value={formatCurrency(total.sum)}
          size={3}
        />
      </div>
      <h3>Vedlegg</h3>
      <div className="row">
        <FileUploader
          name="reimbursement[attachments]"
          files={attachments}
          fileTypes={{ extensions: ["pdf"], mimeTypes: ["application/pdf"] }}
          caseId={props.reimbursement.case_id}
          onChange={(v: Attachment[]) => setAttachments(v)}
          size={8}
        />
      </div>
      <div className="buttons tight">
        {!locked && (
          <button
            className="secondary"
            disabled={!validDraft(reimbursement)}
            type="submit"
            name="submit_action"
            value="save">
            <i className="material-icons">save</i>
            Lagre utkast
          </button>
        )}
        {deleteUrl && (
          <button onClick={handleDelete}>
            <i className="material-icons">delete</i>
            Slett utkast
          </button>
        )}
        {!locked && (
          <button
            disabled={!valid(reimbursement, costs)}
            type="submit"
            name="submit_action"
            value="send">
            <i className="material-icons">send</i>
            Opprett utbetaling
          </button>
        )}
      </div>
    </div>
  );
}

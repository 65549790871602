import { Fragment } from "react";
import { csrfToken, xhr } from "../../lib/request";
import ReportForm from "./ReportForm";

type Props = {
  accept_url: string;
  excel_url: string;
  reimbursement_url?: string;
  report: Report.Report;
};

export default function AcceptReport({
  accept_url,
  excel_url,
  report,
  reimbursement_url
}: Props) {
  const handleAccept = (nextUrl?: string) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    xhr(
      accept_url,
      { method: "POST", authToken: csrfToken() },
      (response: Report.BackendResponse) => {
        if (nextUrl || response.redirect) {
          window.location.assign(nextUrl || response.redirect);
        }
      }
    ).send();
  };

  const excelButton = excel_url && (
    <a href={excel_url} className="button">
      Eksporter til Excel
    </a>
  );

  return (
    <ReportForm report={report} attributes={report.data} locked={true}>
      <section>
        <div className="inner">
          {report.accepted && (
            <Fragment>
              <p>Rapporten er godkjent.</p>
              <div className="buttons">
                {excelButton}
                {reimbursement_url && (
                  <a href={reimbursement_url} className="button primary">
                    Opprett utbetaling
                  </a>
                )}
              </div>
            </Fragment>
          )}
          {!report.accepted && (
            <div className="buttons">
              {excelButton}
              <button className="primary" onClick={handleAccept()}>
                <i className="material-icons">approval</i>
                Godkjenn
              </button>
              {reimbursement_url && (
                <button
                  className="primary"
                  onClick={handleAccept(reimbursement_url)}>
                  <i className="material-icons">approval</i>
                  Godkjenn og opprett utbetaling
                </button>
              )}
            </div>
          )}
        </div>
      </section>
    </ReportForm>
  );
}

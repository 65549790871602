export function formatCurrency(num: number | string) {
  return new Intl.NumberFormat("nb-NO", {
    style: "currency",
    currency: "NOK",
    currencyDisplay: "code"
  })
    .format(+num)
    .replace(/NOK/, "");
}

export function formatDecimal(num: number | string) {
  return new Intl.NumberFormat("nb-NO", {
    style: "decimal"
  }).format(+num);
}

import { Validator } from "../FormInput";
import { skipBlankCosts } from "./useCosts";

function validReimbursement(r: Reimbursement.Reimbursement) {
  const validators = [
    new Validator(r.name, { presence: true }),
    new Validator(r.account_name, { presence: true }),
    new Validator(r.account_number, {
      presence: true,
      validations: ["accountNumber"]
    })
  ];

  return validators.filter((v) => !v.valid()).length === 0;
}

function validCost(cost: Reimbursement.Cost) {
  const validators = [
    new Validator(cost.name, { presence: true }),
    new Validator(cost.sum, {
      presence: true,
      validations: ["number"]
    }),
    new Validator(cost.correction, {
      validations: ["number"]
    })
  ];

  return validators.filter((v) => !v.valid()).length === 0;
}

function validCosts(costs: Reimbursement.Cost[]) {
  return costs.length > 0 && costs.filter((c) => !validCost(c)).length === 0;
}

export function valid(
  r: Reimbursement.Reimbursement,
  costs: Reimbursement.Cost[]
): boolean {
  return validReimbursement(r) && validCosts(skipBlankCosts(costs));
}

export function validDraft(r: Reimbursement.Reimbursement): boolean {
  return r.name ? true : false;
}

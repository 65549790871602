import { ReactNode } from "react";
import { ContentState } from "draft-js";
import styleMap from "./styleMap";

type LinkProps = {
  children: ReactNode;
  contentState: ContentState;
  entityKey: string;
};

export default function Link(props: LinkProps) {
  const data = props.contentState
    .getEntity(props.entityKey)
    .getData() as Immutable.Map<string, string>;
  if ("url" in data) {
    return (
      <a href={data.url as string} style={styleMap["LINK"]}>
        {props.children}
      </a>
    );
  }
}
